$BLACK: #000000;
$WHITE: #FFFFFF;
$RED: #d40000;

$ORANGE: #ffae00;
$ORANGELIGHT: #ffc445;
$MAINGREY: #585858;
$LIGHTGREY: #e6e6e6;
$BORDERGREY: #e6e6e6;
$PINK: #ff3f53;
$NAVY: #003259;
$BLUE: #0084ff;
$LIME: #b6dd00;
$GREEN: #619900;
$YELLOW: #f5c700;

/*$THEMECOLOR: #ffae00;*/
$THEMECOLOR: #184e9e;
$THEMEGREY: #848687;

$COPYGREY: #77797a;

$MAINGREY: #585858;
$BORDERGREY: #e6e6e6;
$BGGREY: #e6e7e7;

$BTNGREY: #717171;
$BTNHOVERBG: #848687;
$BTNHOVERTXT: #FFFFFF;

$BUTTONGREEN: #619900;

$pagePadding: 5rem;


/* Fonts */
/*
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Roboto', sans-serif;
*/
$_STDFont: 'Zilla Slab', serif;
$_STDFontBold: 'Zilla Slab', serif;

$_SECFont: 'Barlow', sans-serif;

$btnHeight:  2.5rem;