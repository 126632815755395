@import '../project-variables';
@import '../project-mixins';


/*
	ABOUT US
	*/
.about-us-right-cont {
	position:relative;
	float:left;
	background-color: $BGGREY;
}
.page-about-us {
	background-color: $BGGREY;
	position:relative;
	.img1, .img2 {
		position:absolute;
		background-position:center center;
		background-size:cover;
		@include breakWidth(1024) {
			display:none;
		}
	}
	.img1 {
		background-color: $THEMECOLOR;
		left: pxtopercent(73, 1920);
	  	top: pxtopercent(72, 958);
	  	width: pxtopercent(714, 1920);
	  	height: pxtopercent(527, 958);
	  	background-image:url(../images/products/IMG_8418.jpg);
	}
	.img2 {
		background-color: $THEMEGREY;
		left: pxtopercent(196, 1920);
	  	top: pxtopercent(494, 958);
	  	width: pxtopercent(533, 1920);
	  	height: pxtopercent(360, 958);
	  	background-image:url(../images/products/IMG_8287.jpg);
	}
}
.about-us-right-cont {
	width:55%;
	float:right;
	padding: 7.2rem 0 7.2rem 0;
	h1, p, li {
		color: $THEMECOLOR;
		text-transform: uppercase;
		text-align:left;
		padding:0 5rem 4rem 20px;
		font-family: $_STDFont;
		font-size:5.5rem;
	}
	p, li {
		font-family: $_SECFont;
		text-align:left;
		font-size:2.5rem;
		color: $COPYGREY;
		padding-bottom:0;
		text-transform: none
	}
	ul {
		margin:0 0 3rem 0;
	}
	li {
		margin:0 0 0 0;
		padding:0 0 4px 2em;
	}
	@include breakWidth(1024) {
		width:100%;
	}
}

.about-us-flex-section {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	.about-us-right-cont {
		float:none;
		width:45%;
	}
	.about-us-left-cont {
		display:flex;
		flex-direction: row;
		flex-wrap:wrap;
		align-items:flex-start;
		width:50%;
		padding: 7.2rem 0 7.2rem 0;
		.img-cont {
			height:300px;
			width:50%;
			border:2px solid #e6e7e7;
			background-size:cover;
			background-position:center center;
			background-repeat: no-repeat;
		}
	}
	@include breakWidth(1024) {
		.about-us-right-cont {
			width:100%;
		}
		.about-us-left-cont {
			display:none;
		}
	}
}


/*
	CONTACT US
	*/
.page-contact-us {
	.col3 {
		padding-top:3rem;
		div {
			float:left;
			width:33.33333%;
			box-sizing:border-box;
			padding:0 2rem 2rem 2rem;
			position:relative;
			span.icon {
				position:relative;
				left:50%;
				margin-left: -3rem;
				width:6rem;
				height:6rem;
				display:block;
				background-size: 95% 95%;
				background-position:center center;
				background-repeat:no-repeat;
				margin-bottom:2rem;
			}
			h2,p {
				color: $THEMEGREY;
				font-size:2rem;
				text-align:center;
				font-family: $_STDFont;
				text-transform: uppercase;
				margin:0 0 0 0;
				padding:0 0 3rem 0;
			}
			p {
				text-transform: none;
				font-size:1.6rem;
				font-family: $_SECFont;
				a {
					color: $THEMEGREY;
					text-decoration:none;
				}
			}
			$col: str-replace('#{$THEMECOLOR}','#');
			&:nth-child(1) {
				span.icon {
					background-image:url(../images/svg/icon-phone.php?col=#{$col});
				}
			}
			&:nth-child(2) {
				span.icon {
					background-image:url(../images/svg/icon-mail.php?col=#{$col});
				}
			}
			&:nth-child(3) {
				span.icon {
					background-image:url(../images/svg/icon-map.php?col=#{$col});
				}
			}
		}
		@include breakWidth(640) {
			div {
				width:100%;
			}
		}
	}
}