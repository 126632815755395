@import './normalize';
@import './project-variables';
@import './project-mixins';

@import url('https://fonts.googleapis.com/css?family=Barlow:100,100i,200i,200,300,300i,400,400i,500,500i,600|Zilla+Slab:300,300i,400,400i,500,500i,600,600i,700,700i');

html {
    min-height: 100% !important;
    font-size:10px;
    @include breakWidth(1920) {
    }
    @include breakWidth(1750) {
		font-size:10px;
	}
	@include breakWidth(1600) {
		font-size:8.5px;
	}
	@include breakWidth(1440) {
		font-size:8px;
	}
	@include breakWidth(1280) {
		font-size:7px;
	}
	@include breakWidth(1024) {
		font-size:7px;
	}
	@include breakWidth(800) {
		font-size:8px;
	}
	@include breakWidth(740) {
		font-size:8px;
	}
	@include breakWidth(460) {
		font-size:7px;
	}
	@include breakWidth(320) {
	}
}
body {
	min-height: 100% !important;
    height: 100%;
    background-color:$WHITE;
    margin-top:14.5rem;
}
h1, h2, h3, h4, h5 {
	font-family: $_STDFont;
	font-weight:normal;
	margin:0px 0px 0px 0px;
	padding:0px 0px 0px 0px;
	text-align:left;
	text-transform:none;
	line-height:1;
	strong {
		font-weight: 600;
	}
}

/*
	DEBUG WINDOW
	*/
.debug-window {
    position:fixed;
    width:35%;
    border:2px solid #FFFFFF;
    background-color: #eaeaea;
    top:20px;
    font-size:11px;
    overflow-y: scroll;
    overflow-x:hidden;
    height:90%;
    padding:10px;
    box-sizing: border-box;
	z-index:99999;
    &.window-right {
	    right:0;
	}
    p {
		border-width:1px 0 0 0;
		border-style:solid;
		border-color: $BLACK;
    }
}
#admin-debug-window-toggle-btn {
	position:fixed;
	top:0;
	right:0;
}
body {
	font-size:1.2rem;
}



button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
button:disabled,
button[disabled]{
	background-color: $BTNGREY !important;
}


button, div, input, textarea, select, p, h1, h2, h3, table, section, footer, article {
	box-sizing: border-box;
}
a, a:visited, a:active {
	color: $THEMECOLOR;
	text-decoration:none;
}
a:hover {
	color: $THEMEGREY;
}
/**
	3 column button container with full screen buttons
	*/
.col3-btns-cont {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	flex-wrap:wrap;
	/*align-content:center;*/
	height:52rem;

	.big-col-btn {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $BGGREY;

		width:33.333333%;
		box-sizing:border-box;
		background-size: cover;
		background-position:center center;
		border:5px solid $WHITE;
		text-decoration: none;
		&:nth-child(1) {
			border-width:10px 5px 10px 0;
		}
		&:nth-child(2) {
			border-width:10px 5px 10px 5px;
		}
		&:nth-child(3) {
			border-width:10px 0 10px 5px;
		}
		div {
			width:70%;
			border:2px solid $WHITE;
			padding:3rem 0 3rem 0;
			@include alpha-attribute('background-color', rgba($BLACK,0.5),$WHITE);
			p {
				text-align:center;
				color: $WHITE;
				margin:0;
				text-decoration: none;
				text-transform: uppercase;
				padding:0 0 0 0;
				&:nth-child(1) {
					font-family: $_STDFont;
					font-size: 3.5rem;
					font-weight:400;
				}
				&:nth-child(3) {
					font-family: $_SECFont;
					font-style:italic;
					font-size:0px;
					font-weight:100;
					transition: 0.5s all;
				}
				@include breakWidth(800) {
					&:nth-child(1) {
						font-size:16px;
					}
				}
			}
			hr {
				border-color: $WHITE;
				border-width:0px;
				margin:0 20% 0 20%;
				transition: 0.5s margin;
			}
			@include breakWidth(800) {
				width:85%;
				padding-left:10px;
				padding-right:10px;
			}
		}
		&:hover > div > hr:nth-child(2) {
			border-width: 1px;
			margin:10px 20% 10px 20%;
			transition: 0.5s margin;
		}
		&:hover > div > p:nth-child(3) {
			font-size:2.2rem;
			transition: 0.5s all;
		}
		@include breakWidth(740) {
			width:100%;
		}
	}

}

.page-cont {
	.href-btn, .btn-qty-cont .cart-qty-input, .btn-qty-cont .qty-controls {
		background-color: $THEMECOLOR;
		border:none;
		color: $WHITE;

		font-size: 2rem;
		text-decoration:none;
		font-family: $_STDFont;
		transition:0.25s all;
		padding:1rem 1.5rem 1rem 1.5rem;
		text-transform:uppercase;

		span {
			width: $btnHeight;
			height: $btnHeight;
			display:inline-block;
			background-size:95% 95%;
			background-repeat: no-repeat;
			background-position:center center;
			vertical-align: middle;
			margin:0 0 0 1rem;
			transition:0.25s all;
		}
		&:hover {
			background-color: $BTNHOVERBG;
			color: $BTNHOVERTXT;
		}
		&:hover > span {
			$hoverCol: str-replace("#{$BTNHOVERTXT}","#");
			background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
		}
	}
	.btn-qty-cont {
		width:70px;
		height:$btnHeight;
		position:relative;
		display:inline-block;
		.cart-qty-input {
			width:100%;
			padding-left:5px;
			border:none;
			background-color: $WHITE;
			color: $THEMECOLOR;
			border:1px solid $BGGREY;
		}
		.qty-controls {
			position:absolute;
			right:1px;
			top:1px;
			width:30px;
			background-color: $WHITE;
			$c: str-replace('#{$MAINGREY}', '#');
			$hc: str-replace('#{$THEMECOLOR}', '#');
			.qty-up, .qty-down {
				position:absolute;
				right:0;
				width:30px;
				height:15px;
				background-image:url(../images/svg/move-vertical-arrow-up.php?col1=#{$c});
				background-size:50% 90%;
				background-repeat: no-repeat;
				background-position:center center;
				background-color: transparent;
				border:none;
				&:hover {
					background-image:url(../images/svg/move-vertical-arrow-up.php?col1=#{$hc});
				}
			}
			.qty-up {
				top:0;
			}
			.qty-down {
				bottom:0;
				background-image:url(../images/svg/move-vertical-arrow-down.php?col1=#{$c});
				&:hover {
					background-image:url(../images/svg/move-vertical-arrow-down.php?col1=#{$hc});
				}
			}
		}
	}

	p {
		.btn-qty-cont {
			display:inline-block;
			vertical-align: middle;
		}
		.href-btn {
			display:inline-block;
			vertical-align: middle;
		}
	}

	.cart-controls-container {
		display:flex;
		justify-content: center;
		flex-direction: row;

	}

}

/*
	PRODUCT LIST TABLE STYLES */
.prod-list-table {
	width:100%;
	margin-bottom:3rem;
	td {
		padding:5px 3px 5px 3px;
		text-align:center;
		font-size:16px;
		color: $THEMEGREY;
		border-color: $THEMEGREY;
		border-style:dotted;
		position:relative;
		&:nth-child(1) {
			width:5%;
		}
		&:nth-child(2) {
			width:10%;
		}
		&:nth-child(3) {
			width:30%;
		}
		&:nth-child(4) {
			width:20%;
		}
		&:nth-child(5) {
			width:10%;
		}
		&:nth-child(6) {
			width:25%;
		}
		&.qty {
			width:50px;
			.cart-qty-input {
				width:50px;
				padding-left:5px;
				border:none;
				background-color: transparent;
			}
			.qty-controls {
				position:absolute;
				right:0;
				top:0;
				height:30px;
				width:30px;
				background-color: transparent;
				$c: str-replace('#{$MAINGREY}', '#');
				$hc: str-replace('#{$THEMECOLOR}', '#');
				.qty-up, .qty-down {
					position:absolute;
					right:0;
					width:30px;
					height:15px;
					background-image:url(../images/svg/move-vertical-arrow-up.php?col1=#{$c});
					background-size:50% 90%;
					background-repeat: no-repeat;
					background-position:center center;
					background-color: transparent;
					border:none;
					&:hover {
						background-image:url(../images/svg/move-vertical-arrow-up.php?col1=#{$hc});
					}
				}
				.qty-up {
					top:0;
				}
				.qty-down {
					bottom:0;
					background-image:url(../images/svg/move-vertical-arrow-down.php?col1=#{$c});
					&:hover {
						background-image:url(../images/svg/move-vertical-arrow-down.php?col1=#{$hc});
					}
				}
			}
		}
		.mobile-break {
			display:none;
		}
		&.cart-td {
			width:30px;
			padding:0 0 0 0;
			.addToCartBtn, .removeFromCart, .updateCartBtn {
				$cartcol: str-replace("#{$THEMECOLOR}","#");
				width:26px;
				height:26px;
				background-image:url(../images/svg/icon-add-to-cart.php?col=#{$cartcol});
				background-size:95% 95%;
				background-repeat: no-repeat;
				background-position:center center;
				&.hide {
					display:none;
				}
			}
			.removeFromCart {
				/*$cartcol: str-replace("#{$RED}","#");*/
				/*$cartcol: "e6e6e6";*/
				$cartcol: str-replace("#{$BTNGREY}","#");
				background-image:url(../images/svg/icon-remove-from-cart.php?col=#{$cartcol});
			}
			.updateCartBtn {
				$cartcol: str-replace("#{$THEMECOLOR}","#");
				background-image:url(../images/svg/icon-update.php?col=#{$cartcol});
			}
		}
	}
	thead {
		tr {
			td {
				border-width:0 0 1px 0;
				text-transform: uppercase;
				font-weight:500;
			}
		}
	}
	tbody {
		tr {
			td {
				border-width:0 1px 0 0;
				&:nth-child(1) {
					border-width:0 1px 0 0;
				}
				&:last-child {
					border-width:0 0 0 1px;
				}
			}
			&:hover {
				@include alpha-attribute('background-color', rgba($THEMECOLOR, 0.2),$WHITE);
			}
		}
	}
	tfoot {
		tr {
			td {
				border-width:0 0 0 0;
				&:nth-child(1) {
					width:50%;
				}
				&:nth-child(2) {
					width:10%;
				}
				&:nth-child(3) {
					width:10%;
				}
				&:nth-child(4) {
					width:30%;
				}
			}
		}
	}

	@include breakWidth(1440) {
		td {
			font-size:14px;
		}
	}

	@include breakWidth(1024) {
		td {
			font-size:12px;
			&:nth-child(1) {
				width:10%;
			}
			&:nth-child(2) {
				display:none;
			}
			&:nth-child(3) {
				width:30%;
			}
			&:nth-child(4) {
				width:20%;
			}
			&:nth-child(5) {
				width:10%;
			}
			&:nth-child(6) {
				width:30%;
			}
			.desktop-space {
				display:none;
			}
			.mobile-break {
				display:inherit;
			}
		}
		tfoot {
			tr {
				td {
					&:nth-child(1) {
						width:60%;
					}
					&:nth-child(2) {
						display:none;
					}
					&:nth-child(3) {
						width:10%;
					}
					&:nth-child(4) {
						width:30%;
					}
				}
			}
		}
	}
	@include breakWidth(640) {
		td {
			font-size:11px;
		}
	}
}

/*
	Floating admin edit button */
.floating-edit-btn {
	position:fixed;
	left:0;
	bottom:0;
	@include alpha-attribute('background-color', rgba($LIME,0.2),$WHITE);
	padding:3px;
	color: $WHITE;
}

/*
	SET STANDARD TEXT STYLES */
h1,h2,h3,p,ul,ol {
	margin:0 0 0 0;
	padding:0 0 0 0;
}
p, li, a {
	font-weight: 300;
	strong {
		font-weight: 600;
	}
	i {
		font-style:italic;
	}
}

h1,h2 {
	color: $THEMECOLOR;
	font-size:4rem;
	margin:0 0 0 0;
	padding:0 2rem 3rem 2rem;
	font-family: $_STDFont;
	text-transform: uppercase;
	text-align:center;
}
h2 {
	font-size:3rem;
	font-weight:400;
	text-transform: uppercase;
	color: $THEMECOLOR;
	text-align:center;
	text-transform: uppercase;
}
p,td,li {
	color: $THEMEGREY;
	font-size:2rem;
	text-align:center;
	font-weight: 200;
	font-family: $_SECFont;
	margin:0 0 0 0;
	padding:0 0 0 0;

	text-transform: none;
}
p,td,li,i,strong,b,u,a {
	.small {
		font-size:1.4rem;
	}
}
p {
	padding:0 2rem 3rem 2rem;
}



@import './_components/topnav';
@import './_components/public-footer';
@import './_components/about-contact-us';
@import './_components/public-forms';


.clear { clear:both; }
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.clearfix-right:after {
  content: "";
  display: table;
  clear: right;
}
.clearfix-left:after {
  content: "";
  display: table;
  clear: left;
}