@import '../project-variables';
@import '../project-mixins';


.share-page-footer-cont {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	background-color: $WHITE;
	height:12rem;

	p {
		color: $THEMECOLOR;
		font-size: 2.5rem;
		text-transform: uppercase;
		margin-right:2rem;
		font-family: $_STDFont;
		padding:0 0 0 0;
	}

	$iconCol: str-replace('#{$MAINGREY}', '#');
	$hoverCol: str-replace('#{$BTNHOVERBG}','#');
	.sharebtn {
		width:6rem;
		height:6rem;
		margin:0 5px 0 5px;
		background-size:98% 98%;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		&.facebook {
			background-image:url(../images/svg/icon-facebook.php?col=#{$iconCol});
			&:hover {
				background-image:url(../images/svg/icon-facebook.php?col=#{$hoverCol});
			}
		}
		&.instagram {
			background-image:url(../images/svg/icon-instagram.php?col=#{$iconCol});
			&:hover {
				background-image:url(../images/svg/icon-instagram.php?col=#{$hoverCol});
			}
		}
		&.twitter {
			background-image:url(../images/svg/icon_twitter.php?col=#{$iconCol});
			&:hover {
				background-image:url(../images/svg/icon_twitter.php?col=#{$hoverCol});
			}
		}
		&.google {
			background-image:url(../images/svg/icon-google-plus.php?col=#{$iconCol});
			margin-right: $pagePadding;
			&:hover {
				background-image:url(../images/svg/icon-google-plus.php?col=#{$hoverCol});
			}
		}
	}

}
footer.page-footer {
	position:relative;
	background-color: $THEMECOLOR;
	.col4-cont {
		.break800 {
			width:100%;
			clear:both;
			display:none;
			@include breakWidth(800) {
				display:block;
			}
		}
		padding:2rem 0 2rem 0;
		.col {
			width:25%;
			box-sizing:border-box;
			padding:2rem 2rem 2rem 2rem;
			float:left;
			&:nth-child(1) {
				padding-left:4rem;
				img {
					width:55%;
				}
			}
			p, h3, li, a {
				color: $WHITE;
				text-align:left;
				font-family: $_SECFont;
				font-size: 2rem;
				font-weight: 300;
				padding-left:0;
			}

			&:nth-child(4) {
				padding-right: $pagePadding;
				.footer-writup {
					padding:0 0 0 2rem;
					margin:0 0 0 0;
				}
			}
			.copyright-p {
				font-size:1.7rem;
				font-weight:400;
			}
			h3 {
				font-weight: 600;
				margin:0 0 3rem 0;
				text-transform: uppercase;
			}
			.sections-list {
				list-style:none;
				margin:0 0 0 0;
				padding:0 0 0 0;
				li {
					a {
						font-weight:200;
						text-transform: uppercase;
						color: $WHITE;
						text-decoration: none;
						padding:0 0 3rem 0;
						display:block;
						&:hover {
							color: $BTNHOVERBG;
						}
					}
				}
			}
			.contact-list {
				$iconCol: str-replace('#{$WHITE}', '#');
				$hoverCol: str-replace('#{$BTNHOVERBG}','#');
				list-style:none;
				margin:0 0 0 0;
				padding:0 0 0 0;
				li {
					background-position:left top;
					background-size:3rem 3rem;
					background-repeat: no-repeat;
					a {
						text-decoration: none;
						padding:0 0 2rem 4rem;
						display:block;
						&:hover {
							color: $BTNHOVERBG;
						}
					}
					&.phone {
						background-image:url(../images/svg/icon-phone.php?col=#{$iconCol});
						&:hover {
							background-image:url(../images/svg/icon-phone.php?col=#{$hoverCol});
						}
					}
					&.mail {
						background-image:url(../images/svg/icon-mail.php?col=#{$iconCol});
						&:hover {
							background-image:url(../images/svg/icon-mail.php?col=#{$hoverCol});
						}
					}
					&.map {
						background-image:url(../images/svg/icon-map.php?col=#{$iconCol});
						&:hover {
							background-image:url(../images/svg/icon-map.php?col=#{$hoverCol});
						}
					}
				}
			}
			@include breakWidth(800) {
				width:50%;
				padding-left:20px;
				padding-right:20px;
			}
			@include breakWidth(460) {
				width:100%;
			}
		}
	}

}