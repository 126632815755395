@import '../project-variables';
@import '../project-mixins';



/*
	PUBLIC FORM STYLES
	*/

.form-section {
	background-color: $BGGREY;
	display: flex;
	flex-direction:column;
	/*justify-content: center;*/
	align-items: center;
	margin:0 0 0 0;
	padding:0 0 0 0;
	min-height:50vh;

	.main-container {
		border:0px solid $RED;
		width:800px;
		padding:5rem 2rem 2rem 2rem;

		h1, h2, p, li {
			.href-btn {
				margin:1rem;
			}
		}
		.href-btn {
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			align-items:center;
			justify-content: space-between;
			
			background-color: $THEMECOLOR;
			padding:0.5rem 1.5rem 0.5rem 1.5rem;
			margin-bottom:3rem;
			border:none;
			
			color: $WHITE;
			font-size: 3rem;
			font-family: $_STDFont;
			text-transform: uppercase;
			text-decoration:none;

			transition:0.25s all;
			span {
				width: $btnHeight;
				height: $btnHeight;
				background-size:95% 95%;
				background-repeat: no-repeat;
				background-position:center center;
				vertical-align: middle;
				margin:0.4rem 0 0 2rem;
				transition:0.25s all;

				margin-bottom:5px;
			}
			&.hide {
				display:none;
			}
			&:hover {
				background-color: $BTNHOVERBG;
				color: $BTNHOVERTXT;
			}
			&:hover > span {
				$hoverCol: str-replace("#{$BTNHOVERTXT}","#");
				background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
			}

			/*
				arrow left */
			&.arrow-left {
				span {
					margin:0 1rem 5px 0;
					$col: str-replace("#{$WHITE}","#");
					background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$col});
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
				&:hover > span {
					$hoverCol: str-replace("#{$BTNHOVERTXT}","#");
					background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
				}		
			}
		}
		a.href-btn {
			/*display:inline-block;*/
			
		}
		@include breakWidth(1280) {
			width:60%;
		}
		@include breakWidth(800) {
			width:85%;
		}
		@include breakWidth(640) {
			width:100%;
		}
		@include breakWidth(400) {
			width:100%;
		}
	}
}

.form-container {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content: space-between;
	padding:0 0 3rem 0;
	position:relative;

	input[type="text"], textarea, select, input[type="email"], input[type="tel"], input[type="password"], .floatinglabel {
		border: 2px solid $THEMECOLOR;
		border-width: 2px 2px 2px 2px;
		border-color: $THEMECOLOR;
		height:5rem;
		line-height:5rem;
		color: $THEMEGREY;
		font-family: $_STDFont;
		font-size: 2rem;
		margin:1rem 0 1rem 0;
		background-color: transparent;
		width:49%;
		padding:0 1.5rem 0 1.5rem;
		&.sizeW100 {
			width:100%;
		}
		&:focus {
			background-color: $WHITE;
		}
		&:focus + .floatinglabel {
			width:0%;
			padding-left:0;
			padding-right:0;
			transition:0.25s all;
			border-right-width:0px;
			border-left-width:0px;
		}
		&.frame_input_marked {
			border-color: $RED;
		}
		&.frame_input_checked {
			border-color: $GREEN;
		}
		@include breakWidth(460) {
			width:100%;
		}
	}

	textarea {
		padding:1.5rem 1.5rem 0 1.5rem;
		min-height:20rem;
		max-height:20rem;
		
		width:100%;
		min-width:100%;
		max-width:100%;

		line-height:100%;
		line-height:1;
	}

	.center-flex-cont {
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items:center;
		width:100%;
		padding:2.5rem 2rem 1.5rem 2rem;
	}

	button {
		padding:1rem 1.5rem 1rem 2rem;
		span {
			$col: str-replace("#{$WHITE}","#");
			background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$col});
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
		&:hover {
			background-color: $BTNHOVERBG;
			color: $BTNHOVERTXT;
		}
		&:hover > span {
			$hoverCol: str-replace("#{$BTNHOVERTXT}","#");
			background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
		}
	}
	.input-cont {
		position:relative;
		width:100%;
		box-sizing: border-box;
	}
	.floatinglabel {
		position:absolute;
		width:100%;
		background-color: $BGGREY;
		top:0;
		left:0;
		box-sizing: border-box;
		transition:0.5s all;
		overflow:hidden;
		margin-left:0;
		margin-right:0;
		text-transform: uppercase;
	}
}




@import '../../../node_modules/toastr/toastr';