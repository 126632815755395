@import '../project-variables';
@import '../project-mixins';



$btnHeight:  2.8rem;
#topnav-cont, .topnav-cont {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	&.float {
		position:fixed;
		top:0;
		left:0;
		width:100%;
		z-index:100;
	}
	.icon-href {
		line-height: $btnHeight;
		font-size: 2rem;
		text-decoration:none;
		font-family: $_SECFont;
		vertical-align: middle;
		transition:0.25s all;
		span {
			width: $btnHeight;
			height: $btnHeight;
			display:inline-block;
			background-size:95% 95%;
			background-repeat: no-repeat;
			background-position:center center;
			vertical-align: middle;
			margin:0 5px 0 0;
			transition:0.25s all;
		}
	}
	.topbar {
		$iconCol: str-replace('#{$WHITE}', '#');
		$hoverCol: str-replace('#{$BTNHOVERBG}', '#');
		display: flex;

		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		-o-flex-direction: row;
		flex-direction: row;

		justify-content: space-between;
		align-items: center;

		background-color: $THEMECOLOR;
		height:5.5rem;

		background-size: 19.1rem 2.1rem;
		background-position: center center;
		background-repeat: no-repeat;
		background-image:url(../images/svg/ap-text.php?col1=FFFFFF&col2=FFFFFF);

		/* FLOATING STYLES */
		&.float {
			background-color: $BGGREY;
			transition:0.5s all;
			height:6rem;
			background-size:5.3rem 3.5rem;
			background-image:url(../images/svg/ap-logo.php);
		}
		.left-side {
			a {
				margin:0 10px 0 10px;
			}
			.home-btn > span {
				background-image:url(../images/svg/icon-home.php?col=#{$iconCol});
				&:hover {
					background-image:url(../images/svg/icon-home.php?col=#{$hoverCol});
				}
			}
			.mail-btn > span {
				background-image:url(../images/svg/icon-mail.php?col=#{$iconCol});
				&:hover {
					background-image:url(../images/svg/icon-mail.php?col=#{$hoverCol});
				}
			}
			.arrowlink {
				$col: str-replace("#{$THEMEGREY}","#");
				$hoverCol: str-replace("#{$BTNHOVERBG}","#");
				font-family: $_STDFont;
				font-weight: 600;
				font-size: 1.6rem;
				line-height:2.5rem;
				height:2.5rem;
				color: $THEMECOLOR;
				text-transform: uppercase;
				span {
					width:2.5rem;
					height:2.5rem;
					margin:0 5px 0 5px;
				}
				span {
					background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$col});
				}
				&:hover {
					color: $BTNHOVERBG;
				}
				&:hover > span {
					background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
				}
				&:nth-child(1) {
					text-align:right;
				}
			}

			/* FLOATING STYLES */
			.arrowlink { display:none; }
			&.float {
				width:50%;
				display:flex;
				.icononly {
					display:none;
				}
				.arrowlink { display:inherit; }
			}
		}

		.middle-cont {
			p {
				color: $WHITE;
				text-align:center;
				font-family: $_STDFont;
				font-size:2rem;
			}
		}

		.right-side {
			height:100%;
			display: flex;
			flex-direction: row;
			align-items: stretch;
			.search-cont {
				height:100%;
				display: flex;
				flex-direction: row;
				align-items: stretch;
				form {
					display: flex;
					flex-direction: row;
					align-items: center;
					align-content: center;
				}
				button {
					width: $btnHeight;
					height: $btnHeight;
					background-size:95% 95%;
					background-repeat: no-repeat;
					background-position:center center;

					background-color: $THEMECOLOR;
					background-image:url(../images/svg/icon-search.php?col=#{$iconCol});
				}
				input[type="text"] {
					background-color: $THEMECOLOR;
					color: $WHITE;
					font-family: $_SECFont;
					text-align:left;
					padding:3px;
					border:none;
					width:100px;
					transition: 0.5s all;
					height: $btnHeight;
					font-size:2rem;
					margin-right:0;
					&:focus {
						margin-right:10px;
						background-color: $WHITE;
						color: $THEMECOLOR;
						border:1px solid $BORDERGREY;
						width:300px;
						transition: 0.5s all;
						@include breakWidth(640) {
							width:100px;
						}
					}
				}
			}

			.user-actions-cont {
				height:100%;
				display: flex;
				flex-direction: row;
				align-items: stretch;

				background-color: $WHITE;
				a {
					margin:0 2px 0 2px;
				}
				$themeCol: str-replace('#{$THEMECOLOR}','#');
				$hoverCol: str-replace('#{$BTNHOVERTXT}','#');
				.icon-href {
					background-color: $WHITE;
					color: $THEMECOLOR;
					display: flex;
					flex-direction: row;
					align-items: center;
					padding:0 10px 0 10px;
					margin:0 0 0 0;
					font-weight:200;
					text-transform: uppercase;
					&:hover {
						color: $BTNHOVERTXT;
						@include alpha-attribute('background-color', rgba($BTNHOVERBG,0.8),$WHITE);
					}
				}
				.login-btn > span {
					background-image:url(../images/svg/icon-user.php?col=#{$themeCol});
				}
				.login-btn {
					&:hover > span {
						background-image:url(../images/svg/icon-user.php?col=#{$hoverCol});
						transition:0.25s all;
					}
				}
				.divider {
					color: $THEMECOLOR;
					font-size:2.5rem;

					display: flex;
					flex-direction: row;
					align-items: center;
					padding:0 0 0 0;
				}
				.cart-btn > span {
					background-image:url(../images/svg/icon-cart.php?col=#{$themeCol});
				}
				.cart-btn {
					padding-right:20px;
					&:hover {
						background-color: $WHITE;
					}
					/*&:hover > span {
						background-image:url(../images/svg/icon-cart.php?col=#{$hoverCol});
						transition:0.25s all;
					}*/
				}
			}

			/* FLOATING STYLES */
			&.float {
				$themeCol: str-replace('#{$THEMECOLOR}','#');
				$hoverCol: str-replace('#{$BTNHOVERTXT}','#');
				.search-cont {
					button {
						background-color: $BGGREY;
						background-image:url(../images/svg/icon-search.php?col=#{$themeCol});
					}
					input[type="text"] {
						background-color: $BGGREY;
						color: $THEMECOLOR;
						&:focus {
							width:25rem;
							@include breakWidth(640) {
								width:100px;
							}
						}
					}
				}
				.user-actions-cont {
					$themeCol: str-replace('#{$THEMECOLOR}','#');
					$white: str-replace('#{$WHITE}','#');
					$hoverCol: str-replace('#{$BTNHOVERTXT}','#');
					background-color: $THEMECOLOR;
					.icon-href {
						background-color: $THEMECOLOR;
						color: $WHITE;
						padding:0 5px 0 5px;
					}
					.login-btn {
						&:hover {
							background-color: $BTNHOVERBG;
						}
					}
					.login-btn > span {
						background-image:url(../images/svg/icon-user.php?col=#{$white});
					}
					.divider {
						color: $WHITE;
						background-color: $THEMECOLOR;
					}
					.cart-btn > span {
						background-image:url(../images/svg/icon-cart.php?col=#{$white});
					}
					.cart-btn {
						padding-right:20px;
						&:hover {
							background-color: $THEMECOLOR;
						}
						/*&:hover {
							background-color: $BTNHOVERBG;
						}*/
					}
				}
			}
		}
		@include breakWidth(640) {
			background-image:none;
		}
	}


	/*
		MAIN BAR WITH LOGO AND us BUTTONS */
	$col: str-replace("#{$THEMEGREY}","#");
	$hoverCol: str-replace("#{$BTNHOVERBG}","#");
	.mainbar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		height:9rem;
		background-color: $BGGREY;
		position:relative;
		/* FLOATING STYLES */
		&.float {
			height:0;
			overflow:hidden;
			transition:0.25s all;
		}
		.back-btn {
			position:absolute;
			display:flex;
			align-items:center;
			vertical-align: middle;
			left:0;
			top:0;
			height:100%;
			width:auto;
			padding:0 2rem 0 2rem;
			text-transform: uppercase;
			font-size:2rem;
			font-weight:500;
			font-family: $_STDFont;
			span {
				$col: str-replace("#{$THEMEGREY}","#");
				$hoverCol: str-replace("#{$WHITE}","#");
				width: $btnHeight;
				height: $btnHeight;
				display:inline-block;
				background-size:95% 95%;
				background-repeat: no-repeat;
				background-position:center center;
				vertical-align: middle;
				margin:0 5px 0 0;
				transition:0.25s all;
				background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$col});
				transform:rotate(90deg);
			}
			&:hover {
				background-color: $BTNHOVERBG;
				color: $WHITE;
			}
			&:hover > span {
				background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
			}
		}

		.icon-href {
			font-family: $_STDFont;
			font-weight: 300;
			font-size: 2.5rem;
			color: $THEMECOLOR;
			text-transform: uppercase;
			width:23rem;
			span {
				margin:0 10px 0 10px;
			}
		}
		.dropdown-btn {
			span {
				background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$col});
			}
			&:hover {
				color: $BTNHOVERBG;
			}
			&:hover > span {
				background-image:url(../images/svg/icon-vertical-arrow.php?col=#{$hoverCol});
			}
			&:nth-child(1) {
				text-align:right;
			}
			&:nth-child(2) {
				text-align:right;
			}
		}
		.logo {
			width:10.7rem;
			height:7rem;
			background-image:url(../images/svg/ap-logo.php);
			background-repeat:no-repeat;
			margin:0 20px 0 20px;
		}
		@include breakWidth(640) {
			.back-btn {
				display:none;
			}
		}
	}

}